export const gradeOptions = [
	{ label: '四年级', value: '4' },
	{ label: '六年级', value: '6' },
	{ label: '八年级', value: '8' }
]

export const genderOptions = [
	{ label: '男', value: 'male' },
	{ label: '女', value: 'female' }
]
